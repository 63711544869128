import React, { Component } from "react";

import Header from "../components/Header/header";
import Footer from "../components/Footer";
import PrivacyText from "../components/Legal/PrivacyText";

class Theme extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="main">
          <PrivacyText/>
        </div>
      </React.Fragment>
    );
  }
}

export default Theme;
import React from "react";
import { connect } from "react-redux";

class Header extends React.Component {
  render() {
    return (
      <React.Fragment>
        <header className="header">
          <nav
            className={`navbar navbar-expand-lg fixed-top ${
              !this.props.terms ? "bg-transparent" : "primary-bg"
            }`}
          >
            <div className="container">
              <a className="navbar-brand" href="/">
                <img
                  src="assets/img/icon-removebg-preview.png"
                  alt="logo"
                  className="img-fluid"
                  height={60}
                  width={60}
                />
              </a>
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="ti-menu"></span>
              </button>

              <div
                className="collapse navbar-collapse h-auto"
                id="navbarSupportedContent"
              >
                {this.props.app ? (
                  <ul className="navbar-nav ml-auto menu">
                    <li>
                      <a href="/">
                        <i style={{fontSize:"18px"}} class="fas fa-home text-white" />
                      </a>
                    </li>
                  </ul>
                ) : (
                  <ul className="navbar-nav ml-auto menu">
                    {/* <li>
                      <a href="/" className="">
                        Home
                      </a>
                    </li> */}
                    {/* <li>
                      <a href="#about" className="page-scroll">
                        About
                      </a>
                    </li>
                    <li>
                      <a href="#screenshots" className="page-scroll">
                        Screenshots
                      </a>
                    </li> */}
                    <li>
                      <a href="#contact" className="page-scroll">
                        Contact
                      </a>
                    </li>
                  </ul>
                )}
              </div>
            </div>
          </nav>
        </header>
      </React.Fragment>
    );
  }
}

export default connect(state => ({}))(Header);

import React, { Component } from "react";

import Header from "../components/Header/header";
import Footer from "../components/Footer";
import PrivacyText from "../components/Legal/PrivacyText";

class Theme extends Component {
  render() {
    return (
      <React.Fragment>
        <Header app={true} terms={true}/>
        <div className="main">
          <PrivacyText/>
        </div>
        <Footer />
      </React.Fragment>
    );
  }
}

export default Theme;
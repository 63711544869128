import React, { Component } from "react";

import Header from "../components/Header/header";
import Footer from "../components/Footer";
import TermsText from "../components/Legal/TermsText";

class Theme extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="main">
          <TermsText/>
        </div>
      </React.Fragment>
    );
  }
}

export default Theme;